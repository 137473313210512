/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&family=Orbitron:wght@400..900&family=Poppins:wght@400;500;600&display=swap'); */

body {
    width: 100%;
    /* max-width: 480px;
    margin: 0 auto; Center the content horizontally */
    height: 100vh;
    background-color: #000 !important;
}

.main {
    max-width: 480px;
    width: 100%;
    margin:auto;
    min-height: 100vh;
}

.mains {
    width: 100%;
    min-height: 100vh;
    background-color: #fff;
}

.shadow-box {
    width: 100%;
    height: 400px;
    background-position: 50%;
    background-size: cover;
    font-family: "Poppins", sans-serif;
    position:"relative";
    display:"flex";
    align-items: "center";
}
.background-shadow-mulberry-violet {
    background: linear-gradient(0deg, rgba(104, 32, 129, 0.70) 0%, rgba(0, 0, 0, 0.07) 100%);
    width: 100%;
    height: 100%;
  }
  
  .background-shadow-raspberry-pink {
    background: linear-gradient(0deg, rgba(129, 32, 113, 0.70) 0%, rgba(0, 0, 0, 0.07) 100%);
    width: 100%;
    height: 100%;
  }
  
  .background-shadow-sparkle-lime {
    background: linear-gradient(0deg, rgba(91, 102, 26, 0.70) 0%, rgba(0, 0, 0, 0.07) 100%);
    width: 100%;
    height: 100%;
  }
  
  .background-shadow-mint-soda {
    background: linear-gradient(0deg, rgba(32, 129, 123, 0.70) 0%, rgba(0, 0, 0, 0.07) 100%);
    width: 100%;
    height: 100%;
  }
  
  .background-shadow-cool-charcoal {
    background: linear-gradient(0deg, rgba(6, 7, 32, 0.70) 0%, rgba(0, 0, 0, 0.07) 100%);
    width: 100%;
    height: 100%;
  }
  
  .background-shadow-jam-red {
    background: linear-gradient(0deg, rgba(98, 34, 45, 0.70) 0%, rgba(0, 0, 0, 0.07) 100%);
    width: 100%;
    height: 100%;
  }
  
  .background-shadow-juicy-orange {
    background: linear-gradient(0deg, rgba(139, 101, 28, 0.70) 0%, rgba(0, 0, 0, 0.07) 100%);
    width: 100%;
    height: 100%;
  }
  
  .background-shadow-pure-sky {
    background: linear-gradient(0deg, rgba(32, 111, 129, 0.70) 0%, rgba(0, 0, 0, 0.07) 100%);
    width: 100%;
    height: 100%;
  }
  

.shadow-1 {
    width: 400px;
    height: 400px;
    position: absolute;
}

.shadow-data {
    position: relative;
    z-index: 10;
}

.font-head-1 {
    color: #FFF;

    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 38px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 34px */
    letter-spacing: -0.17px;
}

.font-head-2 {
    color: #FFF;

    text-align: center;
    font-family: 'Inter';
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 13px */
    letter-spacing: -0.065px;
}

.section-1 {
    width: 100%;
    /* height:100px; */
    margin-top: -30px;
    /* display:flex; */
    min-height:100px;
    /* justify-content: "center"; */
    align-items: center; /* Vertically center */
    overflow: visible;
}
.slick-list{
    margin-top:-50px;
}
.slick-track{
    margin-top:50px;
    overflow: visible;
}
.slider-container {
    /* width: 100%; */

    overflow: visible;
    position: relative;
    /* top:10px; */
    padding: 12px, 20px;
    z-index: 999;
    margin-top:10px;
  }

  .right-arrow {
    /* display: "inline-block"; */
    align-items: center;
  }

.product-desc {
    margin-top: 12px;
}

  .p-product-name{
    display: -webkit-box;
/* width: 160px; */
-webkit-box-orient: vertical;
-webkit-line-clamp: 1;
overflow: hidden;
color: #13161F;

text-overflow: ellipsis;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 100%; /* 12px */
letter-spacing: -0.06px;

margin-bottom: 8px;
  }

  .p-product-price{
    display: -webkit-box;
/* width: 160px; */
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        color: #13161F;

        overflow: hidden;
        color: #13161F;

        text-overflow: ellipsis;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 12px */
        letter-spacing: -0.06px;
        margin-bottom: 24px;
  }



  .slider-title {
    /* display: "inline-block"; */
    display: flex;
    align-items: "center";
    color: #13161F;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.07px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    margin: 0;
    text-decoration: "none";
  }
  .slide {
    overflow: visible;
    z-index: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: rgba(255, 255, 255, 0.60);
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.08);
    backdrop-filter: blur(20px);
    border-radius: 16px;
    transition: transform 0.3s ease;
    
  }
  
  .slider-section {
    margin-top: -50px;
    overflow: visible;
    /* height: 46px; */
  }
  

  .slider-item {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    overflow: visible;
    width: 97% !important;
    border-radius: 16px;
    /* padding: 20px 17px; */
    padding-left: 20px; 
    margin: 0 8px;
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.08);
    backdrop-filter: blur(20px);
    position: relative;
    padding: 17px 12px;
    gap: 4px;
    margin-top: -30px;
    transition: transform 0.3s ease, background 0.3s ease;
    background: rgba(255, 255, 255, 0.60);
    margin-bottom: 20px;
  }
  
  .slider-item.active {
    /* display: inline; */
    padding-left: 20px; 
    overflow: visible;
    border-radius: 16px;
    background: #FFF !important;
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.08) !important;
    backdrop-filter: blur(2px) !important;
    transform: translateY(-8px) !important;
    margin-bottom: 20px;
  }
.my.active {
    overflow: visible;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.08);
    backdrop-filter: blur(2px);
    transform: translateY(-8px); /* Move the active slide 8px above */
  }


.font-head-3 {
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
}

.products-section {
    /* padding:16px; */
}


.p-products-header {
    color: #13161F;

    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    /* line-height: 100%; 16px */
    letter-spacing: -0.08px;
    text-transform: capitalize;
    /* margin-top: 4px; */
    margin-bottom:0px;
}


.font-head-4 {
    font-size: 15px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.font-head-5 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
}

.sliderss p {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
}


.product-item{
    display: flex;
    /* width: 164px;
    height: 164px; */
    /* justify-content: center;
    align-items: center; */

    border-radius: 20px;
    border: 1px solid rgba(19, 22, 31, 0.02);

    background: #D9D9D9;
}

.img-product{
    /* width: 164px;
    height: 164px; */
    /* flex-shrink: 0;     */
    max-width: 100%;
    height: auto;
    border-radius: 20px;
    border: 1px solid rgba(19, 22, 31, 0.03);
    
}

.cart-1 img {
    border: 1px solid rgba(217, 217, 217, 0.5);
    border-radius: 20px;
}
@media screen and (max-width:480px) {

    .mains {
    
    width: 100%;
    max-width: 480px;
    
    }
    
    .col-md-6.col-sm-6 {
    
    width: 50% !important;
    
    }
    
    .section-1 {
    
    min-height: 100px;
    
    margin-top: -30px;
    
    align-items: center;
    
    overflow: visible;
    
    width: 350px;
    
    }
    
    .shadow-1 {
    
    width: 100%;
    
    }


}
